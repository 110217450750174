import { render, staticRenderFns } from "./MegaMenuSearchNewLook.vue?vue&type=template&id=7d3baa52&scoped=true&"
import script from "./MegaMenuSearchNewLook.vue?vue&type=script&lang=js&"
export * from "./MegaMenuSearchNewLook.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenuSearchNewLook.vue?vue&type=style&index=0&id=7d3baa52&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d3baa52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,MegaMenuSearchNewLookResults: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MegaMenu/SearchNewLook/MegaMenuSearchNewLookResults.vue').default,BaseFormInput: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/form/BaseFormInput.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default})
